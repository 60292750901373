<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant="sidebarOpen"
      app
      color="mainDarkBlue"
      dark
      width="260"
    >
      <Sidebar :mini-variant="sidebarOpen" />
    </v-navigation-drawer>

    <Header
      :sidebar-open="sidebarOpen"
      :username="`${firstName} ${lastName}`"
      @drawerChange="drawerChange"
      @handleSidebarStatus="handleSidebarStatus"
    />

    <v-main>
      <v-container
        class="pt-0"
        fluid
        style="position: absolute"
      >
      </v-container>
      <v-container
        fluid
      >
        <router-view></router-view>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import Sidebar from "@/components/MainLayoutComponents/Sidebar/Sidebar";
import Header from "@/components/MainLayoutComponents/Header";
import {notify} from "@/services/notificationService";
import {fetchUserDetails} from "@/services/authService";
import {mapMutations, mapState} from "vuex";

export default {
  name: 'MainLayout',
  components: {Header, Sidebar},
  data: () => ({
    sidebarOpen: null,
    drawer: null,
    dialog: false,
    username: ''
  }),
  computed: {
    ...mapState('auth', ['firstName', 'lastName'])
  },
  beforeMount() {
    // this.$vuetify.theme.dark = localStorage.getItem('themeDark') === 'true';
    this.sidebarOpen = localStorage.getItem('sidebarOpen') === 'true';
    this.fetchUserData()
  },
  methods: {
    ...mapMutations('auth', ['SET_USER_DATA']),
    handleSidebarStatus(value) {
      this.sidebarOpen = value;
    },
    drawerChange(value) {
      this.drawer = value;
      this.sidebarOpen = false;
    },
    async fetchUserData() {
      try {
        const {firstName, lastName, role, isAdmin} = await fetchUserDetails()
        this.SET_USER_DATA({firstName, lastName, role, isAdmin})
      } catch (e) {
        notify('error', 'Nie udało się pobrać danych użytkownika')
      }
    }
  },
}
</script>

<style scoped>

</style>
