<template>
  <v-list-item
    :to="url"
    class="pt-2 pb-2 icon-field"
    link
  >
    <v-list-item-action>
      <div
        class="d-flex justify-space-between"
      >
        <v-icon size="18">{{icon}}</v-icon>
      </div>
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-title class="font-weight-light f-17">{{title}}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "SidebarItem",
  props: {
    url: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    title: {
      title: String,
      required: true
    },
  }
}
</script>

<style scoped>

</style>
